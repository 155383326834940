.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-name input {
  font-size: 11px;
  color: #6351ed;
}

/* .list-name input:focus {
    border-color: #a5a5a5;
} */

.list-name input::placeholder {
  text-align: center;
  color: #8a8888;
}

.recipients-list {
  list-style: decimal;
  max-height: 196px;
  overflow-y: auto;
  margin-bottom: 0px;
}

.recipient-row {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.recipient-row input {
  font-size: 11px;
  color: #6351ed;
}

.recipient-row input:focus {
  border-color: #a5a5a5;
}

.recipient-row input::placeholder {
  color: #8a8888;
}

.submit-btn-create-list:disabled {
  color: white;
  background-color: #adadad;
  cursor: not-allowed;
}

.disable-upload {
  pointer-events: none;
  opacity: 0.5;
}