/* input[type='date']::-webkit-inner-spin-button {
  opacity: 0;
} */

/* input[type='date']::-webkit-calendar-picker-indicator {
  color: rgba(104, 189, 34, 1);
} */

input[type='date']::-webkit-calendar-picker-indicator {
  background: url(/assets/images/drip/calendar_today.svg) center/80% no-repeat;
  opacity: 1;
  margin-right: 5px;
}

input[type='time']::-webkit-calendar-picker-indicator {
  background: url(/assets/images/drip/schedule_violet.svg) center/80% no-repeat;
  opacity: 1;
  margin-right: 5px;
}
