.flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.recipients-list-email {
    list-style: decimal;
    max-height: 343px;
    overflow-y: auto;
}

.recipient-row {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}

.recipient-row input {
    font-size: 11px;
}

.recipient-row input::placeholder {
    color: #8a8888;
}

.submit-btn-add-email:disabled {
    color: white;
    background-color: #adadad;
    cursor: not-allowed;
}

.has-error {
    text-align: left;
    margin-top: -14px;
    color: #6351ed;
    font-size: 12px;
    margin-bottom: 10px;
}