.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.signature p {
  color: #000000;
}

.disclaimer p {
  color: #848484;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* width */

.emailList-ul::-webkit-scrollbar {
  width: 6px;
}

/* Track */

.emailList-ul::-webkit-scrollbar-track {
  background: #e5e5e5;
}

/* Handle */

.emailList-ul::-webkit-scrollbar-thumb {
  background: #dadada;
}

.email-list-selected > div {
  background: #dad6ff;
}

/* Handle on hover */

.react-pdf__Page {
  margin-bottom: 10px;
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}
