#fr-rte .fr-box .fr-wrapper .fr-element p,h1,h2,h3,h4 {
  font-family: Arial, sans-serif;
}

.froala-wrapper-div .fr-wrapper {
  height: 150px;
}

.fr-box .fr-wrapper .fr-element p {
  margin: 0px;
}

.fr-second-toolbar:empty {
  min-height: calc(10px);
  position: relative;
  margin-top: calc(-1px);
  z-index: 2;
}

.fr-second-toolbar {
  border-top: 0;
}
.fr-title-tag p {
  font-size: 24px;
  color: #000;
}

.fr-normal p {
  color: #000;
}

.fr-modal .fr-command.fr-btn svg.fr-svg,
.fr-popup .fr-command.fr-btn svg.fr-svg,
.fr-toolbar .fr-command.fr-btn svg.fr-svg {
  height: 17px;
}

.fr-modal .fr-command.fr-btn i,
.fr-modal .fr-command.fr-btn svg,
.fr-popup .fr-command.fr-btn i,
.fr-popup .fr-command.fr-btn svg,
.fr-toolbar .fr-command.fr-btn i,
.fr-toolbar .fr-command.fr-btn svg {
  display: block;
  text-align: center;
  float: none;
  margin: 4px 5px;
  width: 24px;
}

.fr-box.fr-basic .fr-element {
  font-family: sans-serif;
  color: #414141;
  font-size: 14px;
  line-height: 1.6;
  padding: 10px;
  box-sizing: border-box;
  overflow-x: auto;
  min-height: 60px;
  text-align: left;
}

.fr-toolbar .fr-command.fr-btn,
.fr-popup .fr-command.fr-btn,
.fr-modal .fr-command.fr-btn {
  height: 27px;
}

.fr-toolbar .fr-more-toolbar.fr-expanded {
  height: 37px;
}
.fr-toolbar .fr-command.fr-btn.fr-dropdown:after,
.fr-popup .fr-command.fr-btn.fr-dropdown:after,
.fr-modal .fr-command.fr-btn.fr-dropdown:after {
  top: 12px;
}
