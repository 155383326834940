.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-name input {
  font-size: 11px;
  color: #6351ed;
}

/* .list-name input:focus {
    border-color: #a5a5a5;
} */

.list-name input::placeholder {
  text-align: center;
  color: #8a8888;
}

.recipients-list {
  list-style: decimal;
  max-height: 196px;
  overflow-y: auto;
  margin-bottom: 0px;
}

.recipient-row {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.recipient-row input {
  font-size: 11px;
  color: #6351ed;
}

.recipient-row input:focus {
  border-color: #a5a5a5;
}

.recipient-row input::placeholder {
  color: #8a8888;
}

.submit-btn-create-list:disabled {
  color: white;
  background-color: #adadad;
  cursor: not-allowed;
}

.disable-upload {
  pointer-events: none;
  opacity: 0.5;
}

.search-bar-dropdown{
    position: absolute; 
    z-index: 100; 
    top: 34px; 
    width: 96%;
    max-height: 285px;
    overflow: auto;
}

.search-bar-dropdown ul{
    margin: 0; 
    padding: 0; 
    list-style: none;
    box-shadow: rgb(221 221 221) 0px 1px 2px;
}

.search-bar-dropdown ul li{
    color: black;
    border: black;
    background-color: white;
    width: 100%;
    margin-right: 50%;
    text-align: left;
    padding: 4px 5px; 
    cursor: pointer; 
    transition: all 0.2s ease 0s;  
}


